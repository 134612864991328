import React from "react"
import  "../styles/global.scss"
import { Container, Col, Row } from 'reactstrap';
// import Image from 'react-bootstrap/Image'
import Layout from "../layouts/index"
import { withPrefix } from "gatsby"

const ErrorPage = () => (
	<Layout>
	
		<Container fluid="true" className="error-page">
	        <img src={withPrefix('/assets/img/404.png')} alt="404 Page not found" height="350px" width="700px" class="img-fluid"/>
	    		
	    		<p class ="text-bold-500 font-30">WE ARE SORRY</p>
				<p>The page you requested cannot be found</p>
				<a href="/">Back to home</a>
	    </Container>
 
	</Layout>
 )

export default ErrorPage
